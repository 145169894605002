<template>
  <h1 class="title"><slot></slot></h1>
</template>

<script>
export default {
  name: "Title",
};
</script>

<style lang="scss" scoped>
.title {
  font-size: clamp(1.8rem, 4vw, 2.6rem);
  font-weight: 700;
  text-transform: capitalize;
}
</style>
