<template>
  <main>
    <section class="section hero-section">
      <div class="container">
        <div class="hero">
          <h1 class="hero__title">CONTACT</h1>
        </div>
      </div>
    </section>
    <section class="section contact-section">
      <div class="container">
        <div class="contact-container">
          <div class="section-head">
            <Title>get in touch with us</Title>
            <p>
              You have questions ? Feel free to come in for a chat anytime
            </p>
          </div>
          <div class="contact-cards">
            <a
              class="contact-card"
              :href="$store.state.siteData.social_links.linkedin"
              data-cursor-target
              data-cursor-size="lg"
              target="_blank"
            >
              <div class="contact-card__icon">
                <img src="@/assets/images/icon-linkedin-blue.svg" alt="" />
              </div>
              <p class="contact-card__title">AV DEVS Solutions</p>
            </a>
            <div class="contact-card">
              <div class="contact-card__icon">
                <img src="@/assets/images/icon-location-blue.svg" alt="" />
              </div>
              <p class="contact-card__title">
                404 Nilamber Primero, near Nilamber Circle, Vadodara, Gujarat
                390021
              </p>
            </div>
            <a
              class="contact-card"
              href="mailto:info@avdevs.com"
              data-cursor-target
              data-cursor-size="lg"
            >
              <div class="contact-card__icon">
                <img src="@/assets/images/icon-mail-blue.svg" alt="" />
              </div>
              <p class="contact-card__title">info@avdevs.com</p>
            </a>
          </div>
          <!-- <div class="contact-form">
            <ContactForm />
          </div> -->
          <div class="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29528.7645312073!2d73.12757713012091!3d22.31222521029731!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc620bae73dc9%3A0x5b22bac0f522f786!2sAV%20DEVS%20Solutions%20Pvt.%20ltd.!5e0!3m2!1sen!2sin!4v1633689430975!5m2!1sen!2sin"
              allowfullscreen="false"
              loading="lazy"
            >
            </iframe>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// import ContactForm from "@/components/ui/contactform/ContactForm.vue";
import Title from "@/components/utils/Title.vue";
import { mapActions } from "vuex";

export default {
  name: "ContactPage",
  components: { Title },
  metaInfo: {
    title:
      "Reach out to us with your technological problem | AV DEVS - Agile Vision Developers",
    meta: [
      {
        name: "description",
        content:
          "Contact us today at AV DEVS - Agile Vision Developers at info@avdevs.com or connect with us on Linkedin for any of your technology problems.",
      },
    ],
  },
  methods: {
    ...mapActions(["fillTargets"]),
  },
  mounted() {
    this.fillTargets();
  },
};
</script>

<style lang="scss" scoped>
.hero-section {
  background: url("~@/assets/images/contact-bg.webp") center/cover;
}

.contact-section {
  .contact-container {
    .section-head {
      text-align: center;

      p {
        color: $text-gray;
      }
    }
  }
  .contact-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3.75rem;
    margin-bottom: 4rem;

    .contact-card {
      background-color: $white;
      box-shadow: $subtle-shadow;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2.25rem 1rem;

      &__icon {
        width: 2.75rem;
        margin: 0 auto 1rem;
      }

      &__title {
        font-size: 1.25rem;
        color: $text-gray;
      }

      &:nth-of-type(2) {
        .contact-card__title {
          font-size: 1.175rem;
        }
      }
    }
  }

  .map {
    width: 100%;
    height: 500px;
    margin-bottom: 4rem;

    iframe {
      width: 100%;
      height: 100%;
      border: none;
      box-shadow: $subtle-shadow;
    }
  }
}

@media screen and (max-width: 1024px) {
  .contact-section {
    .contact-cards {
      grid-template-columns: repeat(1, 1fr);
    }

    .map {
      width: 100%;
      height: 500px;
      margin-bottom: 4rem;

      iframe {
        width: 100%;
        height: 100%;
        border: none;
        box-shadow: $subtle-shadow;
      }
    }
  }
}
</style>
